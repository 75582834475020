import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { asyncScheduler, of, scheduled } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { WindowService } from 'src/app/app/services/window.service';
import parseDomainName from 'src/app/app/utils/parse-domain-name';
import {
  DomainActions,
  VerifyCompanyDomain,
  VerifyCompanyDomainError,
  VerifyCompanyDomainSuccess,
} from 'src/app/auth/store/actions/domain.actions';
import { UsersServiceClient } from 'src/app/client/users-service.client';

@Injectable()
export class DomainEffects {


  public readonly checkDomainName = createEffect(() => scheduled(of(null), asyncScheduler).pipe(
    switchMap(() => of(this.windowService.getHostname())),
    map(name => parseDomainName(name)),
    filter(companyDomain => !!companyDomain),
    map(companyDomain => new VerifyCompanyDomain(companyDomain)),
  ));


  public verifyDomainName = createEffect(() => this.storeActions.pipe(
    ofType<VerifyCompanyDomain>(DomainActions.VERIFY_COMPANY_DOMAIN),
    switchMap(action => this.usersServiceClient.verifyDomain({ subdomain: action.payload })
      .pipe(
        map(response => new VerifyCompanyDomainSuccess(response)),
        catchError((error: HttpErrorResponse) =>
          of(new VerifyCompanyDomainError(error.error))),
      ),
    ),
  ));

  constructor(
    private storeActions: Actions,
    private usersServiceClient: UsersServiceClient,
    private windowService: WindowService,
  ) {}

}
