/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';
import { MytitleError } from 'src/app/client/models/client.models';
import { SignUpCompanyParams } from 'src/app/client/models/users.models';

export enum SignUpCompanyActions {
  SIGNUP_COMPANY = '[Auth] Sign-up company',
  SIGNUP_COMPANY_SUCCESS = '[Auth] Sign-up company success',
  SIGNUP_COMPANY_ERROR = '[Auth] Sign-up company error',
}

export class SignUpCompany implements Action {
  public readonly type = SignUpCompanyActions.SIGNUP_COMPANY;
  constructor(public payload: SignUpCompanyParams) {}
}

export class SignUpCompanySuccess implements Action {
  public readonly type = SignUpCompanyActions.SIGNUP_COMPANY_SUCCESS;
}

export class SignUpCompanyError implements Action {
  public readonly type = SignUpCompanyActions.SIGNUP_COMPANY_ERROR;
  constructor(public payload: MytitleError[]) {}
}

export type SignUpCompanyAction =
  SignUpCompany |
  SignUpCompanySuccess |
  SignUpCompanyError;
