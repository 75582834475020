/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';
import { MytitleError } from 'src/app/client/models/client.models';
import {
  GetCompanyInfoResponse,
  PatchCompanyInfoParams,
  PatchCompanyInfoResponse,
} from 'src/app/client/models/users.models';

export enum CompanyInfoActions {
  GET_COMPANY_INFO = '[Auth] Get company info',
  GET_COMPANY_INFO_SUCCESS = '[Auth] Get company info success',
  PATCH_COMPANY_INFO = '[Auth] Patch company info',
  PATCH_COMPANY_INFO_SUCCESS = '[Auth] Patch company info success',
  PATCH_COMPANY_INFO_ERROR = '[Auth] Patch company info error',
}

export class GetCompanyInfo implements Action {
  public readonly type = CompanyInfoActions.GET_COMPANY_INFO;
}

export class GetCompanyInfoSuccess implements Action {
  public readonly type = CompanyInfoActions.GET_COMPANY_INFO_SUCCESS;
  constructor(public payload: GetCompanyInfoResponse) {}
}

export class PatchCompanyInfo implements Action {
  public readonly type = CompanyInfoActions.PATCH_COMPANY_INFO;
  constructor(public payload: PatchCompanyInfoParams) {}
}

export class PatchCompanyInfoSuccess implements Action {
  public readonly type = CompanyInfoActions.PATCH_COMPANY_INFO_SUCCESS;
  constructor(public payload: PatchCompanyInfoResponse) {}
}

export class PatchCompanyInfoError implements Action {
  public readonly type = CompanyInfoActions.PATCH_COMPANY_INFO_ERROR;
  constructor(public payload: MytitleError[]) {}
}

export type CompanyInfoAction =
  GetCompanyInfo |
  GetCompanyInfoSuccess |
  PatchCompanyInfo |
  PatchCompanyInfoSuccess |
  PatchCompanyInfoError;
