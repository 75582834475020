/* eslint-disable max-len */
import { Route } from '@angular/router';
import { AppRoutes } from './app.routes.misc';
import { HomeComponent } from './home/home.component';
import { AuthGuardService } from './services/auth-guard.service';
import { DomainGuardService } from './services/domain-guard.service';
import { UnAuthGuardService } from './services/unauth-guard.service';

/**
 * Root app routes.
 */
export const appRoutes: Route[] = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [UnAuthGuardService, DomainGuardService],
    data: {
      title: 'Protect your company | Mytitle Business',
      description: 'Upload any digital file and get a proof of ownership by date and time.',
      animation: 'Landing',
    },
  },
  {
    path: AppRoutes.CompanySignUp,
    loadChildren: () => import('../pages/company-sign-up/company-sign-up.module').then(m => m.CompanySignUpModule),
    canActivate: [UnAuthGuardService, DomainGuardService],
    data: { animation: 'Signup' },
  },
  {
    path: AppRoutes.EmployeeSignUp,
    loadChildren: () => import('../pages/employee-sign-up/employee-sign-up.module').then(m => m.EmployeeSignUpModule),
    canActivate: [UnAuthGuardService, DomainGuardService],
    data: { animation: 'Signup' },
  },
  {
    path: AppRoutes.SignIn,
    loadChildren: () => import('../pages/sign-in/sign-in.module').then(m => m.SignInModule),
    canActivate: [UnAuthGuardService],
    data: { animation: 'Signin' },
  },
  {
    path: AppRoutes.Dashboard,
    loadChildren: () => import('../pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuardService],
    data: { animation: 'Dashboard' },
  },
  {
    path: AppRoutes.ResetPassword,
    loadChildren: () => import('../pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    canActivate: [UnAuthGuardService],
    data: { animation: 'ResetPassword' },
  },
  {
    path: AppRoutes.ResetPasswordConfirm,
    canActivate: [UnAuthGuardService],
    loadChildren: () => import('../pages/reset-password-confirm/reset-password-confirm.module').then(m => m.ResetPasswordConfirmModule),
    data: { animation: 'ResetPassword' },
  },
  {
    path: AppRoutes.About,
    canActivate: [UnAuthGuardService],
    loadChildren: () => import('../pages/about-us/about-us.module').then(m => m.AboutUsModule),
    data: { animation: 'ResetPassword' },
  },
  {
    path: AppRoutes.EmailConfirmation,
    canActivate: [UnAuthGuardService],
    loadChildren: () => import('../pages/about-us/about-us.module').then(m => m.AboutUsModule),
    data: { animation: 'ResetPassword' },
  },
  {
    path: AppRoutes.Error,
    loadChildren: () => import('../pages/error/error.module').then(m => m.ErrorModule),
    data: { animation: 'Error' },
  },
  {
    path: AppRoutes.NotFound,
    loadChildren: () => import('../pages/error/error.module').then(m => m.ErrorModule),
    data: { animation: 'Error' },
  },
];
