/* eslint-disable max-classes-per-file */
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { VerifyDomainResponse } from 'src/app/client/models/users.models';

export enum DomainActions {
  VERIFY_COMPANY_DOMAIN = '[Auth] Verify company domain',
  VERIFY_COMPANY_DOMAIN_SUCCESS = '[Auth] Verify company domain success',
  VERIFY_COMPANY_DOMAIN_ERROR = '[Auth] Verify company domain error',
}

export class VerifyCompanyDomain implements Action {
  public readonly type = DomainActions.VERIFY_COMPANY_DOMAIN;
  constructor(public payload: string) {}
}

export class VerifyCompanyDomainSuccess implements Action {
  public readonly type = DomainActions.VERIFY_COMPANY_DOMAIN_SUCCESS;
  constructor(public payload: VerifyDomainResponse) {}
}

export class VerifyCompanyDomainError implements Action {
  public readonly type = DomainActions.VERIFY_COMPANY_DOMAIN_ERROR;
  constructor(public payload: HttpErrorResponse) {}
}

export type DomainAction =
  VerifyCompanyDomain |
  VerifyCompanyDomainSuccess |
  VerifyCompanyDomainError;
