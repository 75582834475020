import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UsersServiceClient } from '../../../client/users-service.client';
import {
  ResetPasswordConfirm,
  ResetPasswordConfirmActions,
  ResetPasswordConfirmError,
  ResetPasswordConfirmSuccess,
} from '../actions/reset-password-confirm.actions';

@Injectable()
export class ResetPasswordConfirmEffects {

  public resetPasswordConfirm = createEffect(() => this.storeActions.pipe(
    ofType<ResetPasswordConfirm>(ResetPasswordConfirmActions.RESET_PASSWORD_CONFIRM),
    switchMap(action => this.usersServiceClient.resetPasswordVerify(action.payload)
      .pipe(
        map(() => new ResetPasswordConfirmSuccess()),
        catchError((error: HttpErrorResponse) => of(new ResetPasswordConfirmError(error.error))),
      ),
    ),
  ));

  constructor(
    private storeActions: Actions,
    private usersServiceClient: UsersServiceClient,
  ) {
  }
}
