import { Action } from '@ngrx/store';
import * as jwtDecode from 'jwt-decode';
import { GeoIP } from '../../../client/users-service.client';
import { GetGeoIPAction, GetGeoIPActions } from '../actions/getGeoIP.action';
import { GetUserInfoAction, GetUserInfoActions } from '../actions/getUserInfo.action';
import { LoadTokenAction, LoadTokenActions } from '../actions/loadToken.action';
import { RefreshTokenAction, RefreshTokenActions } from '../actions/refreshToken.action';
import { SignInAction, SignInActions } from '../actions/sign-in.actions';
import {
  SignUpCompanyVerificationAction,
  SignUpCompanyVerificationActions,
} from '../actions/sign-up-company-verification.actions';
import {
  SignUpEmployeeVerificationAction,
  SignUpEmployeeVerificationActions } from '../actions/sign-up-employee-verification.actions';
import { JWTTokenPayload } from '../models/decoded-token-info.models';
import { TokenInfo } from '../models/token-info.models';
import { BusinessUser, BusinessUserObject, User } from '../models/user.models';
import { SignOutAction, SignOutActions } from './../actions/sign-out.actions';

export interface UserState {
  user: User;
  token: TokenInfo;
  geoIP: GeoIP;
  userDetail: BusinessUser;
}

export const initialUserState: UserState = {
  user: null,
  token: null,
  geoIP: null,
  userDetail: null,
};

export function userReducer(
  state = initialUserState,
  generic: Action,
): UserState {

  const action = generic as SignInAction
    | SignOutAction
    | SignUpCompanyVerificationAction
    | SignUpEmployeeVerificationAction
    | RefreshTokenAction
    | LoadTokenAction
    | GetGeoIPAction
    | GetUserInfoAction;

  switch (action.type) {
    case SignOutActions.SIGNOUT:
    case RefreshTokenActions.REFRESH_TOKEN_ERROR: {
      return {
        ...state,
        user: null,
        token: null,
      };
    }

    case SignInActions.SIGNIN_SUCCESS:
    case LoadTokenActions.LOAD_TOKEN_SUCCESS:
    case RefreshTokenActions.REFRESH_TOKEN_SUCCESS:
    case SignUpCompanyVerificationActions.SIGNUP_COMPANY_VERIFICATION_SUCCESS:
    case SignUpEmployeeVerificationActions.SIGNUP_EMPLOYEE_VERIFICATION_SUCCESS: {
      const decodedToken: JWTTokenPayload = jwtDecode(action.payload.token.accessToken);

      return {
        ...state,
        user: {
          ...state.user,
          fullName: decodedToken.name,
          guid: decodedToken.user_guid,
          email: decodedToken.email,
          isEu: decodedToken.is_eu,
          isCompanyOwner: decodedToken.is_company_owner,
        },
        token: {
          ...action.payload.token,
        },
      };
    }

    case GetGeoIPActions.GET_GEO_IP_SUCCESS: {
      return {
        ...state,
        geoIP: action.payload,
      };
    }

    case GetUserInfoActions.GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        userDetail: convertBusinessUserObject(action.payload),
      };
    }
  }

  return state;
}

export function convertBusinessUserObject(entry: BusinessUserObject): BusinessUser {
  return {
    fullName: entry.full_name,
    email: entry.email,
    country: entry.country,
    isEu: entry.is_eu,
    twoFactorAuth: entry.two_factor_auth,
    affiliateCode: entry.affiliate_code,
  };
}
