import { createSelector } from '@ngrx/store';
import { getAuthState } from '../auth.reducers';

export const getUserState = createSelector(
  getAuthState,
  state => state.user,
);

export const getAuthToken = createSelector(
  getUserState,
  userState => userState.token,
);

export const getIsAuthorized = createSelector(
  getAuthToken,
  token => !!token,
);

export const getFullName = createSelector(
  getUserState,
  state => state.user && state.user.fullName,
);

export const getUserInfo = createSelector(
  getUserState,
  state => state.user,
);

export const getUserInfoDetail = createSelector(
  getUserState,
  state => state.userDetail,
);

export const isCompanyOwner = createSelector(
  getUserState,
  state => state.user && state.user.isCompanyOwner,
);

export const getEmail = createSelector(
  getUserState,
  state => state.user && state.user.email,
);

export const getGeoIPState = createSelector(
  getUserState,
  state => state.geoIP,
);

export const getIsEU = createSelector(
  getUserState,
  state => {
    if (state.user) return state.user.isEu;
    else if (state.geoIP) return state.geoIP.is_eu;
    else return false;
  },
);
