import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UsersServiceClient } from '../../../client/users-service.client';
import {
  ResetPassword,
  ResetPasswordActions,
  ResetPasswordError,
  ResetPasswordSuccess,
} from '../actions/reset-password.actions';

@Injectable()
export class ResetPasswordEffects {

  public resetPassword = createEffect(() => this.storeActions.pipe(
    ofType<ResetPassword>(ResetPasswordActions.RESET_PASSWORD),
    switchMap(action => this.usersServiceClient.resetPasswordRequest(action.payload)
      .pipe(
        map(() => new ResetPasswordSuccess()),
        catchError((error: HttpErrorResponse) => of(new ResetPasswordError(error.error))),
      ),
    ),
  ));

  constructor(
    private storeActions: Actions,
    private usersServiceClient: UsersServiceClient,
  ) {
  }
}
