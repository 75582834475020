/* eslint-disable max-classes-per-file */
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { GeoIP } from '../../../client/users-service.client';

export enum GetGeoIPActions {
  GET_GEO_IP = '[Auth] GetGeoIP',
  GET_GEO_IP_SUCCESS = '[Auth] GetGeoIP Success',
  GET_GEO_IP_ERROR = '[Auth] GetGeoIP Error',
}

export class GetGeoIP implements Action {
  public readonly type = GetGeoIPActions.GET_GEO_IP;
}

export class GetGeoIPSuccess implements Action {
  public readonly type = GetGeoIPActions.GET_GEO_IP_SUCCESS;
  constructor(public payload: GeoIP) { }
}

export class GetGeoIPError implements Action {
  public readonly type = GetGeoIPActions.GET_GEO_IP_ERROR;
  constructor(public payload: HttpErrorResponse) { }
}

export type GetGeoIPAction = GetGeoIP | GetGeoIPSuccess | GetGeoIPError;
