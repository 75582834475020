import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { AppState } from './models/app.models';

export const appReducers: ActionReducerMap<AppState> = {
  router: routerReducer,
};

// eslint-disable-next-line @typescript-eslint/array-type
export const metaReducers: MetaReducer<AppState>[] = [];
