/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';
import { MytitleError } from 'src/app/client/models/client.models';
import { SignUpInfoResponse } from 'src/app/client/models/users.models';

export enum SignUpInfoEmployeeActions {
  SIGNUP_INFO_EMPLOYEE = '[Auth] Sign-up employee info',
  SIGNUP_INFO_EMPLOYEE_SUCCESS = '[Auth] Sign-up employee info success',
  SIGNUP_INFO_EMPLOYEE_ERROR = '[Auth] Sign-up employee info error',
}

export class SignUpInfoEmployee implements Action {
  public readonly type = SignUpInfoEmployeeActions.SIGNUP_INFO_EMPLOYEE;
  constructor(public payload: string) {}
}

export class SignUpInfoEmployeeSuccess implements Action {
  public readonly type = SignUpInfoEmployeeActions.SIGNUP_INFO_EMPLOYEE_SUCCESS;
  constructor(public payload: SignUpInfoResponse) {}
}

export class SignUpInfoEmployeeError implements Action {
  public readonly type = SignUpInfoEmployeeActions.SIGNUP_INFO_EMPLOYEE_ERROR;
  constructor(public payload: MytitleError[]) {}
}

export type SignUpInfoEmployeeAction =
  SignUpInfoEmployee |
  SignUpInfoEmployeeSuccess |
  SignUpInfoEmployeeError;
