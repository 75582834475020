import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app/app.routes.misc';
import { NotificationService } from 'src/app/app/services/notification.service';
import { UsersServiceClient } from 'src/app/client/users-service.client';
import { DashboardRoutes } from 'src/app/pages/dashboard/dashboard.routes.misc';
import { ErrorHelperService } from 'src/app/shared/services/error-helper.service';
import {
  CompanySettingsActions,
  DeleteCompanyLogo,
  DeleteCompanyLogoSuccess,
  GetCompanySettings,
  GetCompanySettingsSuccess,
  PatchCompanySettings,
  PatchCompanySettingsError,
  PatchCompanySettingsSuccess,
  SetCompanyLogo,
  SetCompanyLogoError,
  SetCompanyLogoSuccess,
} from '../actions/company-settings.actions';

@Injectable()
export class CompanySettingsEffects {


  public readonly getCompanySettings = createEffect(() => this.storeActions.pipe(
    ofType<GetCompanySettings>(CompanySettingsActions.GET_COMPANY_SETTINGS),
    switchMap(() => this.usersServiceClient.getCompanySettings()
      .pipe(
        map(response => new GetCompanySettingsSuccess(response)),
        this.errorHelperService.raiseError(),
      ),
    ),
  ));


  public readonly patchCompanySettings = createEffect(() => this.storeActions.pipe(
    ofType<PatchCompanySettings>(CompanySettingsActions.PATCH_COMPANY_SETTINGS),
    switchMap(action => this.usersServiceClient.patchCompanySettings(action.payload)
      .pipe(
        map(response => new PatchCompanySettingsSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new PatchCompanySettingsError(error.error))),
      ),
    ),
  ));


  public readonly redirectOnSuccess = createEffect(() => this.storeActions.pipe(
    ofType<PatchCompanySettingsSuccess>(CompanySettingsActions.PATCH_COMPANY_SETTINGS_SUCCESS),
    tap(() => [
      this.notificationService.showSuccess('Settings were changed!'),
      this.router.navigate(
        ['', AppRoutes.Dashboard, DashboardRoutes.Settings]),
      ],
    ),
  ), { dispatch: false });


  public readonly notifyOnError = createEffect(() => this.storeActions.pipe(
    ofType<PatchCompanySettingsError>(CompanySettingsActions.PATCH_COMPANY_SETTINGS_ERROR),
    tap(action => this.notificationService.showError(action.payload[0].message)),
  ), { dispatch: false });


  public readonly setCompanyLogo = createEffect(() => this.storeActions.pipe(
    ofType<SetCompanyLogo>(CompanySettingsActions.SET_COMPANY_LOGO),
    switchMap(action => this.usersServiceClient.setCompanyLogo(action.payload)
      .pipe(
        map(response => new SetCompanyLogoSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new SetCompanyLogoError(error.error))),
      ),
    ),
  ));


  public readonly setCompanySecondLogo = createEffect(() => this.storeActions.pipe(
    ofType<SetCompanyLogo>(CompanySettingsActions.SET_COMPANY_SECOND_LOGO),
    switchMap(action => this.usersServiceClient.setCompanySecondLogo(action.payload)
      .pipe(
        map(response => new SetCompanyLogoSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new SetCompanyLogoError(error.error))),
      ),
    ),
  ));


  public readonly redirectOnLogoSuccess = createEffect(() => this.storeActions.pipe(
    ofType<SetCompanyLogoSuccess>(CompanySettingsActions.SET_COMPANY_LOGO_SUCCESS),
    tap(() => [
      this.notificationService.showSuccess('Logo was successfully changed!'),
      this.router.navigate(
        ['', AppRoutes.Dashboard, DashboardRoutes.Settings]),
      ],
    ),
  ), { dispatch: false });


  public readonly deleteCompanyLogo = createEffect(() => this.storeActions.pipe(
    ofType<DeleteCompanyLogo>(CompanySettingsActions.DELETE_COMPANY_LOGO),
    switchMap(() => this.usersServiceClient.deleteCompanyLogo()
      .pipe(
        map(() => new DeleteCompanyLogoSuccess()),
        this.errorHelperService.raiseError(),
      ),
    ),
  ));


  public readonly deleteCompanySecondLogo = createEffect(() => this.storeActions.pipe(
    ofType<DeleteCompanyLogo>(CompanySettingsActions.DELETE_COMPANY_SECOND_LOGO),
    switchMap(() => this.usersServiceClient.deleteCompanySecondLogo()
      .pipe(
        map(() => new DeleteCompanyLogoSuccess()),
        this.errorHelperService.raiseError(),
      ),
    ),
  ));


  public readonly redirectOnDeletionSuccess = createEffect(() => this.storeActions.pipe(
    ofType<DeleteCompanyLogoSuccess>(CompanySettingsActions.DELETE_COMPANY_LOGO_SUCCESS),
    tap(() => [
      this.notificationService.showSuccess('Logo was deleted!'),
      this.router.navigate(
        ['', AppRoutes.Dashboard, DashboardRoutes.Settings]),
      ],
    ),
  ), { dispatch: false });

  constructor(
    private storeActions: Actions,
    private router: Router,
    private errorHelperService: ErrorHelperService,
    private usersServiceClient: UsersServiceClient,
    private notificationService: NotificationService,
  ) { }
}
