/* eslint-disable max-classes-per-file */
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { BusinessUserObject } from '../models/user.models';

export enum GetUserInfoActions {
  GET_USER_INFO = '[Auth] GetUserInfo',
  GET_USER_INFO_SUCCESS = '[Auth] GetUserInfo Success',
  GET_USER_INFO_ERROR = '[Auth] GetUserInfo Error',
}

export class GetUserInfo implements Action {
  public readonly type = GetUserInfoActions.GET_USER_INFO;
}

export class GetUserInfoSuccess implements Action {
  public readonly type = GetUserInfoActions.GET_USER_INFO_SUCCESS;
  constructor(public payload: BusinessUserObject) { }
}

export class GetUserInfoError implements Action {
  public readonly type = GetUserInfoActions.GET_USER_INFO_ERROR;
  constructor(public payload: HttpErrorResponse) { }
}

export type GetUserInfoAction = GetUserInfo | GetUserInfoSuccess | GetUserInfoError;
