/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';
import { MytitleError } from 'src/app/client/models/client.models';
import {
  CompanySettingsResponse,
  PatchCompanySettingsParams,
  SetCompanyLogoParams,
} from 'src/app/client/models/users.models';

export enum CompanySettingsActions {
  GET_COMPANY_SETTINGS = '[Auth] Get company settings',
  GET_COMPANY_SETTINGS_SUCCESS = '[Auth] Get company settings success',
  PATCH_COMPANY_SETTINGS = '[Auth] Patch company settings',
  PATCH_COMPANY_SETTINGS_SUCCESS = '[Auth] Patch company settings success',
  PATCH_COMPANY_SETTINGS_ERROR = '[Auth] Patch company settings error',
  SET_COMPANY_LOGO = '[Auth] Set company logo',
  SET_COMPANY_SECOND_LOGO = '[Auth] Set company second logo',
  SET_COMPANY_LOGO_SUCCESS = '[Auth] Set company logo success',
  SET_COMPANY_LOGO_ERROR = '[Auth] Set company logo error',
  DELETE_COMPANY_LOGO = '[Auth] Delete company logo',
  DELETE_COMPANY_SECOND_LOGO = '[Auth] Delete company second logo',
  DELETE_COMPANY_LOGO_SUCCESS = '[Auth] Delete company logo success',
}

export class GetCompanySettings implements Action {
  public readonly type = CompanySettingsActions.GET_COMPANY_SETTINGS;
}

export class GetCompanySettingsSuccess implements Action {
  public readonly type = CompanySettingsActions.GET_COMPANY_SETTINGS_SUCCESS;
  constructor(public payload: CompanySettingsResponse) {}
}

export class PatchCompanySettings implements Action {
  public readonly type = CompanySettingsActions.PATCH_COMPANY_SETTINGS;
  constructor(public payload: PatchCompanySettingsParams) {}
}

export class PatchCompanySettingsSuccess implements Action {
  public readonly type = CompanySettingsActions.PATCH_COMPANY_SETTINGS_SUCCESS;
  constructor(public payload: CompanySettingsResponse) {}
}

export class PatchCompanySettingsError implements Action {
  public readonly type = CompanySettingsActions.PATCH_COMPANY_SETTINGS_ERROR;
  constructor(public payload: MytitleError[]) { }
}

export class SetCompanyLogo implements Action {
  public readonly type = CompanySettingsActions.SET_COMPANY_LOGO;
  constructor(public payload: SetCompanyLogoParams) {}
}

export class SetCompanySecondLogo implements Action {
  public readonly type = CompanySettingsActions.SET_COMPANY_SECOND_LOGO;
  constructor(public payload: SetCompanyLogoParams) {}
}

export class SetCompanyLogoSuccess implements Action {
  public readonly type = CompanySettingsActions.SET_COMPANY_LOGO_SUCCESS;
  constructor(public payload: CompanySettingsResponse) {}
}

export class SetCompanyLogoError implements Action {
  public readonly type = CompanySettingsActions.SET_COMPANY_LOGO_ERROR;
  constructor(public payload: MytitleError[]) {}
}

export class DeleteCompanyLogo implements Action {
  public readonly type = CompanySettingsActions.DELETE_COMPANY_LOGO;
}

export class DeleteCompanySecondLogo implements Action {
  public readonly type = CompanySettingsActions.DELETE_COMPANY_SECOND_LOGO;
}

export class DeleteCompanyLogoSuccess implements Action {
  public readonly type = CompanySettingsActions.DELETE_COMPANY_LOGO_SUCCESS;
}

export type CompanySettingsAction =
  GetCompanySettings |
  GetCompanySettingsSuccess |
  PatchCompanySettings |
  PatchCompanySettingsSuccess |
  PatchCompanySettingsError |
  SetCompanyLogo |
  SetCompanySecondLogo |
  SetCompanyLogoSuccess |
  SetCompanyLogoError |
  DeleteCompanyLogo |
  DeleteCompanySecondLogo |
  DeleteCompanyLogoSuccess;
