import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { asyncScheduler, of, scheduled } from 'rxjs';

import { catchError, map, switchMap } from 'rxjs/operators';

import { UsersServiceClient } from 'src/app/client/users-service.client';

import {
  GetGeoIP,
  GetGeoIPActions,
  GetGeoIPError,
  GetGeoIPSuccess,
} from '../actions/getGeoIP.action';

@Injectable()
export class GetGeoIPEffects {


  public readonly triggerGeoIP = createEffect(() => scheduled(of(null), asyncScheduler).pipe(
    map(() => new GetGeoIP()),
  ));


  public getUserInfo = createEffect(() => this.storeActions.pipe(
    ofType<GetGeoIP>(GetGeoIPActions.GET_GEO_IP),
    switchMap(() => this.usersServiceClient.getGeoIP()
      .pipe(
        map(result => new GetGeoIPSuccess(result),
        catchError((error: HttpErrorResponse) => of(new GetGeoIPError(error))),
      )),
    ),
  ));

  constructor(
    private storeActions: Actions,
    private usersServiceClient: UsersServiceClient,
  ) { }
}
