import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { getIsAuthorized } from '../../auth/store/selectors/user.selector';
import { AppRoutes } from '../app.routes.misc';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<any>,
  ) { }

  public canActivate(): Observable<boolean | UrlTree> {
    return this.store.pipe(
      select(getIsAuthorized),
      take(1),
      map(isAuthorized => {
        if (!isAuthorized) return this.router.createUrlTree(['/', AppRoutes.SignIn]);
        return isAuthorized;
      }),
    );
  }
}
