/* eslint-disable max-classes-per-file */
import { Action } from '@ngrx/store';
import { MytitleError } from 'src/app/client/models/client.models';
import { SignInParams } from 'src/app/client/models/users.models';
import { TokenInfo } from '../models/token-info.models';

export enum SignInActions {
  SIGNIN = '[Auth] Sign-in',
  SIGNIN_TWOFA = '[Auth] Sign-in twofa',
  SIGNIN_SUCCESS = '[Auth] Sign-in success',
  SIGNIN_ERROR = '[Auth] Sign-in error',
  CLEAR_SIGNIN = '[Auth] Clear sign-in',
}

export class SignIn implements Action {
  public readonly type = SignInActions.SIGNIN;
  constructor(public payload: SignInParams) {}
}

export class SignInTwoFa implements Action {
  public readonly type = SignInActions.SIGNIN_TWOFA;
  constructor(public payload: string) {}
}

export class SignInSuccess implements Action {
  public readonly type = SignInActions.SIGNIN_SUCCESS;
  constructor(public payload: {token: TokenInfo}) {}
}

export class SignInError implements Action {
  public readonly type = SignInActions.SIGNIN_ERROR;
  constructor(public payload: MytitleError[]) {}
}

export class ClearSignInError implements Action {
  public readonly type = SignInActions.CLEAR_SIGNIN;
}

export type SignInAction =
  SignIn |
  SignInTwoFa |
  SignInSuccess |
  SignInError |
  ClearSignInError;
