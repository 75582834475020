import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app/app.routes.misc';
import { TokenInfo } from 'src/app/auth/store/models/token-info.models';
import { UsersServiceClient } from 'src/app/client/users-service.client';
import {
  SignUpEmployeeVerification,
  SignUpEmployeeVerificationActions,
  SignUpEmployeeVerificationError,
  SignUpEmployeeVerificationSuccess } from '../actions/sign-up-employee-verification.actions';

@Injectable()
export class SignUpEmployeeVerificationEffects {


  public verifyEmployee = createEffect(() => this.storeActions.pipe(
    ofType<SignUpEmployeeVerification>(
      SignUpEmployeeVerificationActions.SIGNUP_EMPLOYEE_VERIFICATION),
    switchMap(action => this.usersServiceClient.signUpEmployeeVerification(action.payload)
      .pipe(
        map(res => {
          const tokenInfo: TokenInfo = {
            expires: new Date(Date.now() + res.expires_in * 1000),
            accessToken: res.access_token,
            refreshToken: res.refresh_token,
          };

          return new SignUpEmployeeVerificationSuccess({token: tokenInfo});
        }),
        catchError((error: HttpErrorResponse) =>
          of(new SignUpEmployeeVerificationError(error.error))),
      ),
    ),
  ));


  public readonly redirectOnSignUpEmployeeSuccess = createEffect(() => this.storeActions.pipe(
    ofType<SignUpEmployeeVerificationSuccess>(
      SignUpEmployeeVerificationActions.SIGNUP_EMPLOYEE_VERIFICATION_SUCCESS),
    tap(() => {
      this.router.navigate(['/', AppRoutes.Dashboard]);
    }),
  ), { dispatch: false });

  constructor(
    private storeActions: Actions,
    private usersServiceClient: UsersServiceClient,
    private router: Router,
  ) {}
}
