import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map, switchMap } from 'rxjs/operators';
import { UsersServiceClient } from 'src/app/client/users-service.client';
import { ErrorHelperService } from 'src/app/shared/services/error-helper.service';
import { GetUserInfo, GetUserInfoActions, GetUserInfoSuccess } from '../actions/getUserInfo.action';
import { BusinessUserObject } from '../models/user.models';

@Injectable()
export class GetUserInfoEffects {

  public getUserInfo = createEffect(() => this.storeActions.pipe(
    ofType<GetUserInfo>(GetUserInfoActions.GET_USER_INFO),
    switchMap(() => this.usersServiceClient.getUserInfoDetail()
      .pipe(
        map((response: BusinessUserObject) => new GetUserInfoSuccess(response)),
        this.errorHelperService.raiseError(),
      ),
    ),
  ));

  constructor(
    private storeActions: Actions,
    private usersServiceClient: UsersServiceClient,
    private errorHelperService: ErrorHelperService,
  ) {
  }
}
