import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  public handleError(error: any) {
    // eslint-disable-next-line no-console
    console.error(error.originalError || error);
    Sentry.captureException(error.originalError || error);
  }
}
