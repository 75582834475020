import { Injectable } from '@angular/core';
import * as jwtDecode from 'jwt-decode';
import { JWTTokenPayload } from '../store/models/decoded-token-info.models';
import { TokenInfo } from '../store/models/token-info.models';

export const PERSISTENCE_TOKEN_KEY = 'mytitle-auth-persistence-token';

@Injectable()
export class PersistenceService {

  /**
   * Persists token across user session.
   *
   * @param token Token to persist
   */
  public persistToken(token: TokenInfo) {
    const serialized: SerializedToken = {
      accessToken: token.accessToken,
      expires: token.expires.toISOString(),
      refreshToken: token.refreshToken,
    };
    const tokenValue = JSON.stringify(serialized);
    localStorage.setItem(PERSISTENCE_TOKEN_KEY, tokenValue);
  }

  /**
   * Loads previously persisted token.
   *
   * @throws when token is empty.
   */
  public loadToken(): TokenInfo {
    const item = localStorage.getItem(PERSISTENCE_TOKEN_KEY);
    if (item) {
      const { accessToken, expires, refreshToken } = JSON.parse(item) as SerializedToken;

      const decodedToken: JWTTokenPayload = jwtDecode(refreshToken);
      if (decodedToken.exp < Date.now() / 1000) {
        this.removeToken();
        throw new Error('The refresh token has expired');
      }
      return { accessToken, expires: new Date(expires), refreshToken };
    }
    throw new Error('Token is empty');
  }

  /**
   * Removes previously persisted token
   */
  public removeToken() {
    localStorage.removeItem(PERSISTENCE_TOKEN_KEY);
  }
}

interface SerializedToken {
  accessToken: string;
  expires: string;
  refreshToken: string;
}
